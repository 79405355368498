<head>
  <meta name="csrf-token" id="token" content="{{ csrf_token() }}"></meta>
</head>

<template>
  <div
    class="about-banner"
    style="background-image: url('/images/contact-us.jpeg')"
  >
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark">
        <div class="container">
          <a class="p-3" href="/"
            ><img src="/images/logo.png" alt="MB logo" height="100" width="100"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ms-auto text-end">
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/about">About Us</a>
              </li>
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/products">Products</a>
              </li>
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/partners">Partners</a>
              </li>
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/careers">Careers</a>
              </li>
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="container">
      <div
        class="about-banner_header row justify-content-center justify-content-md-end"
      >
        <div class="col-lg-5 text-white my-3 py-3 my-md-5 py-md-5">
          <h2 class="mb-3 fw-light"><strong>Contact Us!</strong></h2>
          <form @submit.prevent="submit">
            <div class="form-floating mb-3">
              <input
                id="name"
                name="name"
                type="text"
                class="form-control"
                placeholder="Full Name"
                v-model="form.name"
              />
              <label class="text-dark" for="name">Full Name</label>
              <h6 v-if="nameError === true" class="text-light">
                Oooooops, you forgot to add your name!
              </h6>
            </div>
            <div class="form-floating mb-3">
              <input
                id="email"
                name="email"
                type="email"
                class="form-control"
                placeholder="email@email.com"
                v-model="form.email"
              />
              <label class="text-dark" for="email">Email</label>
              <h6 v-if="emailError === true" class="text-light">
                Oooooops, you forgot to provide us with an email address!
              </h6>
            </div>
            <div class="form-floating mb-3">
              <textarea
                id="message"
                name="message"
                class="form-control"
                placeholder="Leave a comment here"
                style="height: 100px"
                v-model="form.body"
              ></textarea>
              <label class="text-dark" for="message">Message</label>
              <h6 v-if="bodyError === true" class="text-light">
                Oooooops, you forgot to add a message!
              </h6>
            </div>
            <button type="submit" class="button button-outline-light mb-3">
              <span>Submit</span>
            </button>
            <h6 v-if="success === true" class="text-light">
                Thank you, your enquiry has been submitted successfully! We will be in touch ASAP
            </h6>
            <h6 v-if="error === true" class="text-light">
                Oooops! There's been an error, please refresh the page and try again
            </h6>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row m-3">
      <div class="col-sm">
        <div class="career-banner_badge p-3 m-3 border">
          <h5 class="text-center border-bottom mb-3">
            <strong>Location</strong>
          </h5>
          <p class="text-center fw-lighter">
            MediaBlanket Ltd, <br />
            Lloyds House, <br />
            18-22 Lloyd Street, <br />
            Manchester, <br />
            M2 5WA
          </p>
        </div>
      </div>
      <div class="col-sm">
        <div class="career-banner_badge p-3 m-3 border">
          <h5 class="text-center border-bottom mb-3 fw-light"><strong>Email</strong></h5>
          <p class="text-center fw-lighter">hello@mediablanket.co.uk</p>
        </div>
      </div>
      <div class="col-sm">
        <div class="career-banner_badge p-3 m-3 border">
          <h5 class="text-center border-bottom mb-3 fw-light">
            <strong>Office Hours</strong>
          </h5>
          <p class="text-center fw-lighter">
            Monday, Tuesday & Thursday: <br />
            10am - 12pm and 2pm - 4pm <br /> <br />
            Wednesday & Friday: <br />
            10am - 4pm
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.4082065083153!2d-2.2495964841364553!3d53.479035772464975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1c25d20b51b%3A0x64b8f5ce7ba86f04!2sLloyds%20House%2C%20Manchester!5e0!3m2!1sen!2suk!4v1669974686889!5m2!1sen!2suk" height="450" class="m-5 rounded-3" style="border:1;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>


</template>

<script>
import axois from 'axios'

window.Laravel = {csrfToken: '{{ csrf_token() }}'}

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        body: "",
      },
      nameError: false,
      emailError: false,
      bodyError: false,
      success: false,
      error: false,
    };
  },
  methods: {
    submit() {

      if (this.form.name === "") {
        this.nameError = true;
        return;

      } if (this.form.email === "") {
        this.emailError = true;
        return;

      } if (this.form.body === "") {
        this.bodyError = true;
        return;

      } else {
        axios.post('/contact', this.form, window.Laravel)
        .then((res) => {
          this.success = true;
          console.log(res);
        }) 
        .catch((err) => {
          this.error = true;
          console.log(err);
        })
        .finally(() => {
          console.log(this.form);
        })
  
        // this.success = true;
        
        this.form.name = "";
        this.form.email = "";
        this.form.body = "";
        
        this.nameError = false;
        this.emailError = false;
        this.bodyError = false;
      }

    },
  },
};
</script>
