<template>
  <div
    class="about-banner"
    style="background-image: url('/images/careers-background.jpeg')"
  >
    <div>
      <nav class="navbar navbar-expand-md navbar-dark">
        <div class="container">
          <a class="p-3" href="/"
            ><img src="/images/logo.png" alt="MB logo" height="100" width="100"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ms-auto text-end">
              <li class="nav-item p-3">
                <a class="nav-link" href="/about">About Us</a>
              </li>
              <li class="nav-item p-3">
                <a class="nav-link" href="/products">Products</a>
              </li>
              <li class="nav-item p-3">
                <a class="nav-link" href="/partners">Partners</a>
              </li>
              <li class="nav-item p-3">
                <a class="nav-link" href="/careers">Careers</a>
              </li>
              <li class="nav-item p-3">
                <a class="nav-link" href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="container">
        <div class="about-banner_header row justify-content-center">
          <div class="col-md text-center text-white my-3 py-3 my-md-5 py-md-5">
            <h1 class="m-2 m-md-3 fw-light">
              <strong>Supa 2025 - Our Strategy</strong>
            </h1>
            <h4 class="m-2 m-md-4 fw-light">Four Strategic Pillars</h4>
          </div>
        </div>
        <div class="container">
          <div class="row m-3">
            <div class="col-sm">
              <div class="career-banner_badge p-3 m-3 border">
                <h4 class="text-center fw-light mb-3 border-bottom">Customers</h4>
                <a href="#customers"><button
                  @click="render1"
                  class="title-button button-outline-light"
                >
                  <strong>more...</strong>
                </button></a>
              </div>
            </div>
            <div class="col-sm">
              <div class="career-banner_badge p-3 m-3 border">
                <h4 class="text-center fw-light mb-3 border-bottom">Growth</h4>
                <a href="#growth"><button
                  @click="render2"
                  class="title-button button-outline-light"
                >
                  <strong>more...</strong>
                </button></a>
              </div>
            </div>
            <div class="col-sm">
              <div class="career-banner_badge p-3 m-3 border">
                <h4 class="text-center fw-light mb-3 border-bottom">Good</h4>
                <a href="#good"><button
                  @click="render3"
                  class="title-button button-outline-light"
                >
                  <strong>more...</strong>
                </button></a>
              </div>
            </div>
            <div class="col-sm">
              <div class="career-banner_badge p-3 m-3 border">
                <h4 class="text-center fw-light mb-3 border-bottom">Performance</h4>
                <a href="#performance"><button
                  @click="render4"
                  class="title-button button-outline-light"
                >
                  <strong>more...</strong>
                </button></a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="option1 === true" id="customers">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-9">
                <div
                  class="career-banner_badge text-center text-white p-3 m-3 border"
                >
                  <h2 class="mb-3 fw-light border-bottom">Supa Customers</h2>
                  <p class="lh-base fw-lighter mb-3">
                    We want to be recognised as the most innovative provider of
                    fintech products and services through digitalisation,
                    customisation, flexibility, excellent customer experience
                    and a unique brand offering multiple routes to market for
                    the customer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="option2 === true" id="growth">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-9">
                <div
                  class="career-banner_badge text-center text-white p-3 m-3 border"
                >
                  <h2 class="mb-3 fw-light border-bottom">Supa Growth</h2>
                  <p class="lh-base fw-lighter mb-3">
                    We want to be the global leader in sustainable fintech
                    solutions by extending geographical coverage and customer
                    reach with new partners in the digital fintech ecosystem,
                    value-accretive acquisitions and new solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="option3 === true" id="good">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-9">
                <div
                  class="career-banner_badge text-center text-white p-3 m-3 border"
                >
                  <h2 class="mb-3 fw-light border-bottom">Supa Good</h2>
                  <p class="lh-base fw-lighter mb-3">
                    We will place people and corporate social responsibility at
                    the heart of the business model. We aim to invest in our
                    people to ensure all company objectives are met.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="option4 === true" id="performance">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-9">
                <div
                  class="career-banner_badge text-center text-white p-3 m-3 border"
                >
                  <h2 class="mb-3 fw-light border-bottom">Supa Performance</h2>
                  <p class="lh-base fw-lighter mb-3">
                    Our goal is to generate value over the economic cycle within
                    a robust business operating framework to grow the company in
                    a cost-efficient manner.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
        <div class="career-banner_badge text-white p-5 m-4 border">
          <h2 class="mb-3 border-bottom fw-light text-left text-sm-center">Supa 2025 - Our Strategy</h2>
          <p class="lh-lg mb-3 fw-lighter text-left">
            Our 3-year strategic plan, Supa 2025, outlines the main strategic 
            objectives that will support our ambitious development. These objectives 
            leverage the transformation that we started 2 years ago to seize growth 
            opportunities that we see in the medium to long-term future.
            With this new strategic plan, we are positioning ourselves at the heart of 
            the evolving Fintech world and aim to strengthen our competitive edge.
            Our ambition is to become a fully integrated sustainable fintech provider 
            and the global leader in our industry.
          </p>
        </div>
  </div>
  <div class="container">
        <div class="career-banner_badge text-white p-5 m-4 border">
          <h2 class="mb-3 fw-light text-left text-sm-center border-bottom">Supa 2025’s ambitions translate into key deliverables to drive sustainable growth</h2>
          <p class="lh-lg mb-5 fw-lighter text-left">
            Our 3-year strategic plan, Supa 2025, outlines the main strategic 
            objectives that will support our ambitious development. These objectives 
            leverage the transformation that we started 2 years ago to seize growth 
            opportunities that we see in the medium to long-term future.
            With this new strategic plan, we are positioning ourselves at the heart of 
            the evolving Fintech world and aim to strengthen our competitive edge.
            Our ambition is to become a fully integrated sustainable fintech provider 
            and the global leader in our industry.
          </p>
          <p class="border-bottom"></p>
          <h4 class="mb-3 mt-5 fw-light text-left">Personal Credit Platform and API</h4>
          <p class="lh-lg mb-5 fw-lighter">
            We plan to grow our Personal Credit Platform offering and see significant 
            growth potential in each of our segments:  multinationals, Internal, White-
            label and cross sector. We will continue to shape our value-added 
            solutions through digitalization, customization, flexibility and outstanding 
            service quality, prioritizing shared, connected, multi-modal and 
            sustainable components, to meet our needs and expectations as they 
            evolve.
            We plan to grow the platform from a UK proposition into the US, Australia 
            and have double digit white-label channels in place as well related 
            channels offering car finance and more.         
          </p>
          <p class="border-bottom"></p>
          <h4 class="mb-3 mt-5 fw-light text-left">The future is multi-cycle and multi-channel</h4>
          <p class="lh-lg mb-5 fw-lighter">
            Supa 2025 will transform our business model to one where we are offering
            a more diverse range of products across all customer’s needs and 
            requirements both B2B and B2C utilising our state-of-the-art digital 
            platforms. We plan to grow Credit Knowledge into a more general 
            environment for customers; not focusing on just finance.         
          </p>
          <p class="border-bottom"></p>
          <h4 class="mb-3 mt-5 fw-light text-left">SupaSmart – a network of connected tools</h4>
          <p class="lh-lg mb-5 fw-lighter">
            We are building a suite of tools that can be used across Mediablanket and 
            beyond; including: Energy, Credit Reporting, Mobile Phones and Finance. 
            This ecosystem will allow us drive revenues and business in a strategic 
            manner offering solutions on a B2B and B2C basis.         
          </p>
          <p class="border-bottom"></p>
          <h4 class="mb-3 mt-5 fw-light text-left">Affiliates – Revolutionising Revenue</h4>
          <p class="lh-lg mb-5 fw-lighter">
            Building on our expertise in the affiliate marketing arena, we aim to build 
            a market leading proposition; Connecting businesses with customers around the world
            across the retail, telecommunications, travel and finance verticals.         
          </p>
          <p class="border-bottom"></p>
          <h4 class="mb-3 mt-5 fw-light text-left">121Money.com – helping customers out of debt</h4>
          <p class="lh-lg mb-5 fw-lighter">
            121Money has ambitious growth plans to grow into a fully grown debt and IVA solutions 
            company. 2022 saw the set-up of the new venture, plans to grown into a self-sustainable 
            company during 2023 and beyond are in place with increased staffing and FCA authorisations
            to enable a smoother journey for the customer.         
          </p>
          <p class="border-bottom"></p>
          <h4 class="mb-3 mt-5 fw-light text-left">Driving investment in digital capabilities</h4>
          <p class="lh-lg mb-5 fw-lighter">
            Over the next 3 years, we will be accelerating investment in our digital 
            capabilities and plan to invest to support our digital ambitions. Invested 
            across our strategic pillars, we aim to build customer obsession at all 
            levels of our organization in order to bring our clients more flexibility and 
            a unique experience.             
          </p>
          <p class="border-bottom"></p>
          <h4 class="mb-3 mt-5 fw-light text-left">A multi-dimensional sustainability approach for Corporate Social Responsibility and 
            People</h4>
          <p class="lh-lg mb-5 fw-lighter">
            We are placing corporate social responsibility at the heart of everything 
            we do for a sustainable future. To fully support this ambition, we have 
            defined a multi-dimensional sustainability approach with a number of 
            extra-financial objectives to support our CSR strategy and ensure that our 
            business activities have a positive impact on society, including:             
          </p>
          <ul>
            <li class="mb-3 text-start fw-lighter">Being a committed and responsible employer</li>
            <li class="mb-3 text-start fw-lighter">Look to improve performance and realise objectives through the 
            management and development of our people. People are our most 
            valuable commodity. Central the Mediablanket’s success is having the
            right blend of talent, motivation and leadership</li>
            <li class="mb-3 text-start fw-lighter">A healthy is not an ethical priority for the business but changes that 
            are being made will make a significant contribution to productivity.</li>
            <li class="mb-5 text-start fw-lighter">Implementing responsible business culture & practices such as ESG 
            criteria in 100% of our policies, processes and controls with external 
            stakeholders</li>
          </ul>
          <p class="mb-3 border-bottom"></p>
          <h4 class="mb-3 mt-5 fw-light text-left">Supa 2025 – Our future vision</h4>
          <p class="lh-lg mb-3 fw-lighter">
            The fintech world is undergoing major transformations which are 
            reshaping our industry. We are confident that we are well positioned to 
            continue to grow significantly over the next 3 years and that the strategic 
            objectives in the context of our new strategic plan, Supa 2025, will 
            successfully address these challenges and seize new opportunities to 
            deliver tangible value and strong returns. We are fully committed to 
            delivering our vision of becoming a fully integrated sustainable fintech 
            provider and a leader in our industry.             
          </p>
        </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option1: false,
      option2: false,
      option3: false,
      option4: false,
    };
  },
  methods: {
    render1() {
      this.option1 = true;
      this.option2 = false;
      this.option3 = false;
      this.option4 = false;
    },
    render2() {
      this.option1 = false;
      this.option2 = true;
      this.option3 = false;
      this.option4 = false;
    },
    render3() {
      this.option1 = false;
      this.option2 = false;
      this.option3 = true;
      this.option4 = false;
    },
    render4() {
      this.option1 = false;
      this.option2 = false;
      this.option3 = false;
      this.option4 = true;
    },
  },
};
</script>
