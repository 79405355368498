<template>
  <div
    class="about-banner"
    style="background-image: url('/images/careers-background.jpeg')"
  >
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark">
        <div class="container">
          <a class="p-3" href="/"
            ><img src="/images/logo.png" alt="MB logo" height="100" width="100"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ms-auto text-end">
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/about">About Us</a>
              </li>
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/products">Products</a>
              </li>
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/partners">Partners</a>
              </li>
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/careers">Careers</a>
              </li>
              <li class="nav-item p-0 p-lg-1 fs-6">
                <a class="nav-link" href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="container">
        <div
          class="about-banner_header row justify-content-center"
        >
          <div
            class="col-md text-center text-lg-start text-white my-3 py-3 my-md-5 py-md-5"
          >
            <h1 class="m-2 m-md-3">
                Like what we do? <br />
                Join us!
            </h1>
            <h5 class="m-2 m-md-4 fw-lighter">
              Invest in your future and work alongside amazing people.
            </h5>
          </div>
        </div>
        <div class="container">
          <div class="row m-3">
            <div class="col-sm">
              <div class="career-banner_badge p-3 m-3 border">
                <h4 class="text-center">
                  PHP (Laravel) <br />
                  Developer
                </h4>
                <a href="#job1"><button @click="render1" class="button button-outline-light">
                  <span>Apply Now</span>
                </button></a>
              </div>
            </div>
            <div class="col-sm">
              <div class="career-banner_badge p-3 m-3 border">
                <h4 class="text-center">
                  Frontend <br />
                  Developer
                </h4>
                <a href="#job2"><button
                  @click="render2"
                  class="button button-outline-light"
                >
                  <span>Apply Now</span>
                </button></a>
              </div>
            </div>
            <div class="col-sm">
              <div class="career-banner_badge p-3 m-3 border">
                <h4 class="text-center">Business Admin Apprentice</h4>
                <a href="#job3"><button
                  @click="render3"
                  class="button button-outline-light"
                >
                  <span>Apply Now</span>
                </button></a>
              </div>
            </div>
            <div class="col-sm">
              <div class="career-banner_badge p-3 m-3 border">
                <h4 class="text-center">Lead Generation Executive</h4>
                <a href="#job4"><button
                  @click="render4"
                  class="button button-outline-light"
                >
                  <span>Apply Now</span>
                </button></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="option1 === true" id="job1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="career-banner_badge text-start text-white p-3 m-3 border">
            <h2 class="mb-3">PHP (Laravel) Developer</h2>
            <p class="mb-3">Job Type: Permanent</p>
            <p class="mb-3">Required Language: English</p>
            <p class="text-uppercase mb-3 fw-light text-decoration-underline">
              Job Description: <br />
            </p>
            <p class="lh-lg mb-3 fw-lighter">
              As a full stack PHP Developer, you will be working on the
              company’s inhouse system, helping to rewrite the front end using
              up to date technology such as REACT or VUE.js. You will be working
              alongside senior stakeholders to transform and redevelop this
              system; you will take part in regular bug fixing and scoping out
              redevelopment for a number of modules. The technologies you will
              be using include PHP, Laravel, AWS, Vue.js and API experience is
              required. <br />
              As well working on the in-house CMS, there are other exciting
              business areas you will be working on.
            </p>
            <p class="text-uppercase mb-3 fw-light text-decoration-underline">
              <strong>PHP Developer - Candidate Description:</strong> <br />
            </p>
            <p class="lh-lg mb-3 fw-lighter">
              The ideal PHP Developer will have solid experience in a full stack
              environment using PHP, Laravel, and JavaScript. You will have
              experience in at least one framework including Vue.js. Angular or
              REACT would be beneficial. <br />
              We have ongoing training programmes in place using Udemy. <br />
              If this position sounds like you and you would like to be
              considered, please apply now! <br />
              Competitive package on offer for the right candidate, starting in
              the region of £30k-£35k 
              <br />
              <br />
              <strong
                >To apply please send your CV and if available examples of
                previous work or published code to hello@mediablanket.co.uk or
                call 0161 711 0415 for an informal chat.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="option2 === true" id="job2">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="career-banner_badge text-start text-white p-3 m-3 border">
            <h2 class="mb-3">Frontend Developer</h2>
            <p class="lh-lg mb-3 fw-lighter">
                Mediablanket Ltd are looking for an ambitious, self starting, Front End Developer to work closely within their busy development team. For you to be the successful candidate, you must have solid knowledge with some or all of the following technologies.
            </p>
            <ul>
                <li class="fw-lighter mb-3">HTML</li>
                <li class="fw-lighter mb-3">CSS/SASS</li>
                <li class="fw-lighter mb-3">Javascript, jQuery</li>
                <li class="fw-lighter mb-3">VueJS/Angular JS Frameworks</li>
                <li class="fw-lighter mb-3">Bootstrap 3/4</li>
                <li class="fw-lighter mb-3">Asynchronous programming with AJAX</li>
                <li class="fw-lighter mb-3">Integrating REST APIs</li>
                <li class="fw-lighter mb-3">Version control – Git, Mercurial, etc.</li>
                <li class="fw-lighter mb-3">Testing</li>
            </ul>
            <p class="lh-lg mb-3 fw-lighter">
                Bonus points given for:
            </p>
            <ul>
                <li class="fw-lighter mb-3">Commercial Experience x 1 year</li>
                <li class="fw-lighter mb-3">PHP</li>
                <li class="fw-lighter mb-3">GIT</li>
                <li class="fw-lighter mb-3">MYSQL</li>
                <li class="fw-lighter mb-3">SEO</li>
                <li class="fw-lighter mb-3">Affillate Marketing</li>
            </ul>
            <p class="lh-lg mb-3 fw-lighter">
                Mediablanket are looking for a talented Junior front end developer to work on the creation of several websites. You need to be a passionate, driven, self starter who can demonstrate strong design principles for web and/or mobile platforms. You’ll learn the ability to manage time effectively and will gain an excellent attention to detail.
            </p>
            <ul>
                <li class="fw-lighter mb-3">Basic salary of £18,000 – £25,000</li>
                <li class="fw-lighter mb-3">Online coding courses available</li>
                <li class="fw-lighter mb-3">Working with new technology</li>
                <li class="fw-lighter mb-3">9-5 working hours</li>
                <li class="fw-lighter mb-3">Company social events including: team nights out & much more</li>
                <li class="fw-lighter mb-3">Make a difference day – 1 paid day off work to volunteer at a charity of your choice</li>
                <li class="fw-lighter mb-3">Free gym membership</li>
            </ul>
            <p class="mb-3">
              <strong>**** NO AGENCIES ****</strong> <br />
            </p>
            <p class="lh-lg mb-3 fw-light">
                Job Type: Full-time
            </p>
            <p class="lh-lg mb-3 fw-light">
                Salary: £18,000.00 to £25,000.00 /year
            </p>
            <p class="lh-lg mb-3">
              <strong
                >To apply please send your CV and if available examples of
                previous work or published code to hello@mediablanket.co.uk or
                call 0161 711 0415 for an informal chat.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="option3 === true" id="job3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="career-banner_badge text-start text-white p-3 m-3 border">
            <h2 class="mb-3">Business Administration Apprectice</h2>
            <p class="mb-3 fw-lighter">MediaBlanket Ltd are looking to find the right person with the right mindset – who are hungry to succeed in a fast paced business, where opportunities really are endless.</p>
            <p class="text-uppercase mb-3 fw-light text-decoration-underline">
                What is the role? <br />
            </p>
            <p class="lh-lg mb-3 fw-lighter">
                As a Business Administration Apprentice you will have the opportunity to join one of our busy administration based departments such as complaints, customer service, sales administration or insurance. You will undertake tasks such as data entry & other tasks as required by your line manager. You must have excellent inter-personal skills and be able to work effectively under pressure in a fast paced working environment.
            </p>
            <p class="text-uppercase mb-3 fw-light text-decoration-underline">
              What can you expect to be doing? <br />
            </p>
            <ul>
                <li class="fw-lighter mb-3">Handling inbound calls from both existing and new customers</li>
                <li class="fw-lighter mb-3">Delivering exceptional customer service at all times to internal & external stakeholders</li>
                <li class="fw-lighter mb-3">Adhering to FCA Rules, Regulations and Principles at all times</li>
                <li class="fw-lighter mb-3">Achieve individual targets and KPI’s</li>
                <li class="fw-lighter mb-3">Taking responsibility for your own workload reporting any concerns to the line manager</li>
            </ul>
            <p class="text-uppercase mb-3 fw-light text-decoration-underline">
              What can we expect from you? <br />
            </p>
            <ul>
                <li class="fw-lighter mb-3">Ability to work under pressure and in a fast paced environment</li>
                <li class="fw-lighter mb-3">Ability to handle confidential information with integrity</li>
                <li class="fw-lighter mb-3">Excellent attention to detail</li>
                <li class="fw-lighter mb-3">Strong administration and organisational skills</li>
                <li class="fw-lighter mb-3">Good time management skills</li>
                <li class="fw-lighter mb-3">Excellent communication skills, both written and verbal</li>
                <li class="fw-lighter mb-3">IT literate with strong keyboard skills</li>
                <li class="fw-lighter mb-3">Confident telephone manner with strong rapport building skills</li>
            </ul>
            <p class="text-uppercase mb-3 fw-light text-decoration-underline">
              What will you get in return? <br />
            </p>
            <ul>
                <li class="fw-lighter mb-3">Pension Scheme</li>
                <li class="fw-lighter mb-3">Company reward scheme</li>
                <li class="fw-lighter mb-3">Free fruit, tea and coffee</li>
                <li class="fw-lighter mb-3">Refer a friend reward scheme</li>
                <li class="fw-lighter mb-3">Company social events including: summer parties, team nights out & much more</li>
                <li class="fw-lighter mb-3">Make a difference day – 1 paid day off work to volunteer at a charity of your choice</li>
                <li class="fw-lighter mb-3">Free gym membership</li>
                <li class="fw-lighter mb-3">Free Friday Food</li>
            </ul>
            <p class="text-uppercase mb-3 fw-light text-decoration-underline">
              Why choose us? <br />
            </p>
            <p class="lh-lg mb-3 fw-lighter">
                You can be certain that with us you’re guaranteed to develop your career. You’ll be learning new things every single day thanks to our dedicated training team. We really believe in our people & strive to ensure every single person reaches their full potential. <br />
                If you’re excited about a new challenge or simply want a chat with a member of our recruitment team, you’re in the right place.<br /> <br />
                Get in touch.<br /> <br />
                Job Type: Full-time<br />
                Salary: £8,320.00 /year
                <br />
              <br />
              <strong
                >To apply please send your CV to hello@mediablanket.co.uk or
                call 0161 711 0415 for an informal chat.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="option4 === true" id="job4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div class="career-banner_badge text-start text-white p-3 m-3 border">
            <h2 class="mb-3">Lead Generation Executive</h2>
            <p class="text-uppercase mb-3 fw-light text-decoration-underline">
              Job Description <br />
            </p>
            <p class="mb-3 fw-light">
                As a Lead Generation Exec, we are looking for an individual who is passionate about delivering exceptional customer service. <br />
            </p>
            <p class="mb-3 fw-light">
                Your day to day responsibilities will include: <br />
            </p>
            <ul>
                <li class="fw-lighter mb-3">Handling calls from our customers</li>
                <li class="fw-lighter mb-3">Making outbound calls from leads generated for finance and transferring the customer effectively</li>
                <li class="fw-lighter mb-3">Obtaining all relevant information in regards to Clients finances</li>
                <li class="fw-lighter mb-3">Providing customers with a great level of service</li>
                <li class="fw-lighter mb-3">Having an excellent understanding of the business in order to provide customers with a knowledgeable service</li>
                <li class="fw-lighter mb-3">Ability to communicate effectively</li>
                <li class="fw-lighter mb-3">Using in-house CMS systems to report and log calls</li>
            </ul>
            <p class="mb-3 fw-light">
                For this position we are looking for the following experience: <br />
            </p>
            <ul>
                <li class="fw-lighter mb-3">Previous experience in Debt Management/ Lead Gen call centre environment is a MUST</li>
                <li class="fw-lighter mb-3">Proven record of hitting targets</li>
                <li class="fw-lighter mb-3">Empathy towards clients</li>
                <li class="fw-lighter mb-3">Ability to work in a fast paced environment</li>
                <li class="fw-lighter mb-3">Excellent communication skills</li>
                <li class="fw-lighter mb-3">Organised with attention to detail</li>
            </ul>
            
            <p class="lh-lg mb-3 fw-light">
                Job Type: Full-time<br />
                Salary: £17,000.00-£20,000.00 per year
                <br />
              <br />
              <strong
                >To apply please send your CV to hello@mediablanket.co.uk or
                call 0161 711 0415 for an informal chat.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option1: false,
      option2: false,
      option3: false,
      option4: false,
    };
  },
  methods: {
    render1() {
      this.option1 = true;
      this.option2 = false;
      this.option3 = false;
      this.option4 = false;
    },
    render2() {
      this.option1 = false;
      this.option2 = true;
      this.option3 = false;
      this.option4 = false;
    },
    render3() {
      this.option1 = false;
      this.option2 = false;
      this.option3 = true;
      this.option4 = false;
    },
    render4() {
      this.option1 = false;
      this.option2 = false;
      this.option3 = false;
      this.option4 = true;
    },
  },
};
</script>
